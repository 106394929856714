
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import StockPhotos from '@/components/toolkit/details/row/custom/StockPhotos.vue'
  import { mapActions } from 'vuex'

@Component({
  components: { StockPhotos },
  methods: {
    ...mapActions('resources/form', ['fetchData']),
  },
})
  export default class LetterApprovalCell extends Cell {
  link: string = ''

  async mounted () {
    const { item } = this

    if (!item?.closingReason?.isSigned) return

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'evaluation' } },
    })

    const file = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: item.id } },
          {
            parameter: {
              _and: [
                { process: { id: { _eq: process[0].id } } },
                { name: { _eq: 'approval_letter' } },
              ],
            },
          },
        ],
      },
      force: true,
    })

    this.link = file?.[0]?.file.uri || ''
  }
  }
